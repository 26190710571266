import React from "react";
import image4 from '../../assets/4.webp';

const LittleSquareEntreprise3 = () => {


    return(
        <div className="square" >
            <img src={image4}  alt="multimédia"/>
        </div>
    )
}

export default LittleSquareEntreprise3;