import React from "react";
import image4 from "../../assets/4.webp";

const ReverseSquareContact = () => {

    return(
        <div className=" reversesquarecontact">
            <>
                <img src={image4} alt=""></img>
            </>
        </div>
    )
}

export default ReverseSquareContact;