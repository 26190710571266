import React from "react";
import LittleSquareEntreprise4 from "./LittleSquareEntreprise4";
import BigSquareEntreprise4 from "./BigSquareEntreprise4";


const WhiteBarEntreprise4 = ({isFirstInstance}) => {

        return (
            <>
                <div className="whitebar">
                    <LittleSquareEntreprise4 />
                    <BigSquareEntreprise4 />
                </div>
            </>
        )

}

export default WhiteBarEntreprise4;