import React from "react";
import image8 from '../../assets/8_mini.webp';

const LittleSquareEntreprise = () => {


    return(
        <div className="square" >
            <img src={image8} alt="multimédia"/>
        </div>
    )
}

export default LittleSquareEntreprise;