import React from "react";
import LittleSquareEntreprise3 from "./LittleSquareEntreprise3";
import BigSquareEntreprise3 from "./BigSquareEntreprise3";


const WhiteBarEntreprise3 = ({isFirstInstance}) => {

        return (
            <>
                <div className="whitebar">
                    <LittleSquareEntreprise3 />
                    <BigSquareEntreprise3 />
                </div>
            </>
        )

}

export default WhiteBarEntreprise3;