import React from "react";

const darkBarTraduction = () => {



    return (
        <div className="darkbar">

            
        </div>

    )
}
export default  darkBarTraduction