import React from "react";
import ReverseBigSquareEntreprise from "./ReverseBigSquareEntreprise";
import ReverseLittleSquareEntreprise from "./ReverseLittleSquareEntreprise";


const WhiteBarEntreprise2 = () => {

        return (
            <>
                <div className="whitebar">
                    <ReverseLittleSquareEntreprise />
                    <ReverseBigSquareEntreprise />
                </div>
            </>
        )

}

export default WhiteBarEntreprise2;