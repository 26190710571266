import React from "react";
import Test from '../../assets/test.jpg';

const LittleSquareEntreprise3 = () => {


    return(
        <div className="square" >
            <img src={Test} className="speed_speaking_picture" alt="multimédia"/>
        </div>
    )
}

export default LittleSquareEntreprise3;