import React from "react";


const DarkBar = () => {



    return (
        <div className="darkbar">
            
        </div>
    )
}

export default DarkBar;