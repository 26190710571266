import React from "react";

const EmptyWhiteBarEntreprise = () => {


    return (
        <section className="emptyWhiteBar">

        </section>
    )

}

export default EmptyWhiteBarEntreprise