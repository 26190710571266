import React from "react";

const DarkBarEntreprise2 = () => {



    return (
        <div className="darkbar">

            
        </div>

    )
}
export default  DarkBarEntreprise2