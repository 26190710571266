import React from "react";
import image6 from '../../assets/6.webp';

const ReverseLittleSquareEntreprise = () => {



    return(
        <div className="reversesquare">
            <img src={image6} alt="drapeaux"/>
        </div>
    )
}

export default ReverseLittleSquareEntreprise;