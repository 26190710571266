import React from "react";

const DarkBarEntreprise = () => {



    return (
        <div className="darkbar bare_to_disappear">

            
        </div>

    )
}
export default  DarkBarEntreprise