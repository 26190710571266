import React from "react";





const BigSquareEntreprise3 = () => {

    return (

        <div className="bigsquare" >
            <div className="div_formation" >
               
                <h2 className="div_formation_title">Speed speaking in English</h2>
                <p >Tous les 2èmes et 4èmes mardi du mois à l'Oregans à Laval à 20h30</p>
                
                <h2 className="div_formation_title">Pour plus d'infos...</h2>
                <p>Pensez à visiter notre page Facebook dédiée à cet évènement <a  href="https://www.facebook.com/groups/513842279196659?locale=fr_FR">en cliquant ici</a></p>
               
            </div>
        </div>
    )
}

export default BigSquareEntreprise3;